::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}
::-webkit-scrollbar-thumb {
    background: #ababab;
    border: 0px none #ffffff;
    border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
    background: #ababab;
}
::-webkit-scrollbar-thumb:active {
    background: #adadad;
}
::-webkit-scrollbar-track {
    background: transparent;
    border: 0px none #ffffff;
    border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
    background: transparent;
}
::-webkit-scrollbar-track:active {
    background: transparent;
}
::-webkit-scrollbar-corner {
    background: transparent;
}