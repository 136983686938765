.react-time-picker {
    width: 100%;
}
.react-time-picker__wrapper {
    border: 1px solid #CBD5E0;
    border-radius: 0.375rem;
    padding: 0px 15px;
    outline: 2px solid transparent;
    outline-offset: 2px;
    height: 2.5rem;
    width: 100%;
}